import React, { useEffect, useState } from 'react';
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import NavPagination from '../../../../../../components/NavPagination';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../../../../components/SearchBar';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import { deleteCustomerAccount, fetchCustomerAccountData, restoreCustomerAccount } from '../../../../../../data/UserCustomerRequests';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';
import DeleteModal from '../../../../../../components/DeleteModal';
import InfoIcon from '../../../../../../assets/img/icons/InfoIcon';
import AddCustomerAccountForm from '../forms/AddCustomerAccountForm';
import { PlusIcon } from 'swap-frontend-library';
import FormModal from '../../../../../../components/FormModal';
import TextInput from '../../../../../../components/TextInput';
import MainButton from '../../../../../../components/MainButton';
import { updateUserPasswordManager } from '../../../../../../data/AuthRequests';

const CustomersTable = () => {
    const { t } = useTranslation();
    
    const [customerData, setCustomerData] = useState([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [visibleRows, setVisibleRows] = useState([1,1]);
    const [ loaded, setLoaded ] = useState(false); // temporary -> TODO: Implement fuzzy search

    const handleFetchCustomerData = () => {
        fetchCustomerAccountData().then(data => {setCustomerData(data.data); setFilteredData(data.data); setLoaded(true)})
    }

    useEffect(() => {
        handleFetchCustomerData();
    }, [])

    const [deleteItem, setDeleteItem] = useState<any>(undefined);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [addCustomerAccountModalOpen, setAddCustomerAccountModalOpen] = useState(false);
    const [deleteModalValidation, setDeleteModalValidation] = useState("");
    const handleOpenDeleteModal = async (deleteItem: any) => {
        setDeleteItem(deleteItem);
        setDeleteModalOpen(true);
    }

    const handleDeleteCustomerAccount = async (id: string) => {
        await deleteCustomerAccount(id);
        handleFetchCustomerData();
        setDeleteModalOpen(false);
        setDeleteItem(undefined);
    }
    
    const handleRestoreCustomerAccount = async (id: string) => {
        console.log(id);
        await restoreCustomerAccount(id);
        handleFetchCustomerData();
    }

    return (
    <div className="relative mt-5 shadow-md sm:rounded-lg w-full">
        {deleteModalOpen ? <DeleteModal isOpen={deleteModalOpen} setOpen={setDeleteModalOpen} deleteHandler={() => handleDeleteCustomerAccount(deleteItem.id)} content={
            <div>
                <p className='mb-3'>Soll der Anbieter {deleteItem.email} wirklich gelöscht werden?</p>
            </div>
        } /> : <></>}
        {addCustomerAccountModalOpen ? <FormModal isOpen={addCustomerAccountModalOpen} setOpen={setAddCustomerAccountModalOpen}><AddCustomerAccountForm /></FormModal> : <></> }
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            {t("customerAccounts")}
            <p className="mt-1 text-sm font-normal text-gray-500">{t("totalItems", {length: customerData.length, itemType: `${t("vendor")}`})}</p>
        </div>
        
        <div
            className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200"
        >
            <div className="w-full mb-1">
                <div className="items-center justify-between block flex max-sm:flex-col">
                    <div className='max-sm:w-full xl:w-96'>
                        <SearchBar translatedPlaceholderItem={`E-Mail`} originalData={customerData} setFilteredData={setFilteredData} filterKey={"email"} />
                    </div>

                    <div className='flex gap-4'>
                        <div className="flex items-center h-full max-sm:w-full">
                            <button
                            onClick={() => setAddCustomerAccountModalOpen(true)}
                            type="button"
                            data-refresh
                            className="inline-flex max-sm:w-full items-center justify-center h-full w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                            >
                            <div className='w-4 h-4'><PlusIcon /></div>
                            {t("add")}
                            </button>
                        </div>
                        <div className="flex items-center h-full max-sm:w-full">
                            <button
                            onClick={() => handleFetchCustomerData()}
                            type="button"
                            data-refresh
                            className="inline-flex max-sm:w-full items-center justify-center h-full w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                            >
                            <RefreshIcon iconColor='white' />
                            {t("refresh")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {(!loaded) ? (<LoadingSpinner />) : (
            <>
            <table className="w-full overflow-x-auto text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <TableHeader header={[`${t("companyName")}`, "E-Mail", `${t("firstname")}`, `${t("lastname")}`, `${t("createdOn")}`, "", ""]} />
                </thead>
                {
                    (filteredData.length == 0) ? (<tbody className='text-center'><tr><td colSpan={5} className='h-16'>No customer accounts available...</td></tr></tbody>) : (
                        filteredData.slice(visibleRows[0]-1,visibleRows[1]).map(({id, customerDetails, email, firstname, lastname, createdDate, deletedDate} : any, index : number) => {
                            const date : Date = new Date(Date.parse(createdDate));
                            const dateString : string = `${date.getDate().toString().padStart(2,"0")}.${(date.getMonth()+1).toString().padStart(2,"0")}.${date.getFullYear()}`;

                            let rowItems = [index, customerDetails.company, email, firstname, lastname, dateString, <ResetPasswordModal userId={id} />];
                            
                            if (deletedDate != null) { 
                                const deletedDateDate : Date = new Date(Date.parse(deletedDate));
                                const deletedDateString : string = `${deletedDateDate.getDate().toString().padStart(2,"0")}.${(deletedDateDate.getMonth()+1).toString().padStart(2,"0")}.${deletedDateDate.getFullYear()}`;
                                rowItems.push(
                                <div className='text-gray-400'>
                                    <p 
                                    onClick={() => handleRestoreCustomerAccount(id)}
                                    className='cursor-pointer'>Restore</p>
                                    <p className='text-xs font-light italic'>Deleted {deletedDateString}</p>
                                </div>
                                ) 
                            }

                            return (
                                <tbody>
                                    <TableRow 
                                        rowItems={rowItems} 
                                        deleteHandler={deletedDate == null ? () => handleOpenDeleteModal({id, email}) : undefined}
                                    />
                                </tbody>
                            )
                        })
                    )
                }
            </table>
            {/* <NavPagination totalItems={1000} visibleRows={[0,10]} setVisibleRows={undefined} handleChange={() => {}} /> */}
            <NavPagination totalItems={filteredData.length} visibleRows={visibleRows} setVisibleRows={setVisibleRows} />
            </>
            )
        }
    </div>
  )
}

const ResetPasswordModal = ({userId} : {userId:any}) => {
    const [ open, setOpen ] = useState(false);
    const [ success, setSuccess ] = useState<boolean | null>(null);
    const [ password, setPassword ] = useState("");

    const handleUpdateUserPasswordManager = async () => {
        const response = await updateUserPasswordManager(userId, password);
        setSuccess(response);
    }

    useEffect(() => {
        setPassword("");
        setSuccess(null);
    }, [open])

    return (
        <>
        {open ? (
            <FormModal isOpen={open} setOpen={setOpen} size='medium'>
                <div className='flex gap-4 flex-col'>
                    <h3 className="mb-4 text-xl font-medium text-gray-900">Set new password</h3>
                    {
                        success == null ? (
                            <>
                                <TextInput placeholder='New password' textValue={password} setTextValue={setPassword} />
                                <MainButton text={'Save'} func={() => handleUpdateUserPasswordManager()} />
                            </>
                        ) : (
                            <>
                                {
                                    success == false ? <p>Failed to update password</p> : <>Updated successfully</>
                                }
                            </>
                        )
                    }
                </div>
            </FormModal>
        ) : <></>}
        <div onClick={() => setOpen(true)} className='cursor-pointer'>
            Reset password
        </div>
        </>
    )

}

export default CustomersTable