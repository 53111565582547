import React, { useEffect, useState } from 'react'
import { DropzoneUpload, UploadIcon } from 'swap-frontend-library'
import { uploadPlatformLogo } from '../../../../../../data/PlatformRequests';

// upload-logo

const PlatformLogoSettings = () => {
    // const [uploadedFile, setUploadedFile] = useState<any | null | undefined>(null)

    const handleUpload = (file : Blob) => {
      if (typeof file === 'undefined' || !file) return;
      console.log(file);
      uploadPlatformLogo(file);

    }

    return (
      <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            Logo
            <p className="mt-1 text-sm font-normal text-gray-500">Set page logo <span className='font-semibold'>(SVG only)</span></p>
        </div>
  
        <div className='p-4 bg-white'>
          {/* <DropzoneUpload setUploadedFile={setUploadedFile} label={'Upload new page logo'} fileType={'SVG'} maxFileSize={'20 mb'} /> */}
          <label htmlFor='input-upload' className="cursor-pointer inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300">
            <div className='h-6 w-6'><UploadIcon /></div>
            Upload
            <input onChange={(event) => {if (event.target.files) { handleUpload(event.target.files[0])}}} id='input-upload' type="file" name="file" className="hidden"></input>
          </label>
        </div>
      </div>
    )
}

export default PlatformLogoSettings