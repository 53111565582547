import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SidebarElement from './components/SidebarElement';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import useSPNavItems from './hooks/useSPNavItems';
import { ArrowRightIcon, BurgerIcon } from 'swap-frontend-library';

const Index = () => {
  const role = useSelector((state: RootState) => state.user.role);
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [collapsedSidebar, setCollapsedSidebar] = useState(false);
  
  const handleCollapseSidebar = () => {
    if (mobileSidebarOpen && !collapsedSidebar) { setMobileSidebarOpen(false); return; }
    setCollapsedSidebar(prev => !prev)
  }

  const menu_items = useSPNavItems(collapsedSidebar, handleCollapseSidebar);
  return (
    <div className='min-h-[100dvh] bg-gray-50 flex'>

      <aside id="logo-sidebar"
      className={`${mobileSidebarOpen ? "max-sm:block" : "max-sm:hidden"} ${collapsedSidebar ? "w-16" : "w-fit"} max-sm:fixed z-40 h-[100dvh] pt-20 transition-transform bg-white border-r border-gray-200`}>
        <div className="h-full px-3 pb-4 pt-3 overflow-y-auto bg-white">
            <ul className="space-y-2">
              {
                menu_items.map((value: any, index: number) => {
                  if (value.topAlign && Object.keys(value.roleLink).includes(role)) {
                    return (<SidebarElement label={value.label} link={value.roleLink[role]} icon={value.icon} labelHidden={collapsedSidebar} onClick={value.onClick} />)
                  }
                })
              }
            </ul>
            <ul className="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200">
              {
                menu_items.map((value: any, index: number) => {
                  if (!value.topAlign && Object.keys(value.roleLink).includes(role)) {
                    return (<SidebarElement label={value.label} link={value.roleLink[role]} icon={value.icon} labelHidden={collapsedSidebar} onClick={value.onClick} />)
                  }
              }
              )}
            </ul>
        </div>
      </aside>

      <div 
      className={`h-[100dvh] w-full overflow-x-hidden pt-20`}>
        <div className='fixed bottom-5 left-4 shadow-lg z-[2000] bg-white border rounded-full md:hidden p-4 text-gray-400' onClick={() => setMobileSidebarOpen(prev => !prev)}><div className={`w-8 h-8 transition-all ${mobileSidebarOpen ? "rotate-180" : ""}`}><ArrowRightIcon /></div></div>
        <Outlet context={[!collapsedSidebar, {open: "64", closed: "16"}, setCollapsedSidebar]} />
      </div>

      
    </div>
  )
}

export default Index