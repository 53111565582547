import React, { useState } from 'react'
import { DropzoneUpload, MainButton, TextInput } from 'swap-frontend-library'
import PlatformLogoSettings from './features/PlatformLogoSettings'
import PlatformEmailSettings from './features/PlatformEmailSettings'
import PlatformCrmSettings from './features/PlatformCrmSettings'
import PlatformApiKeySettings from './features/PlatformApiKeySettings'
import PlatformProductPricingSettings from './features/PlatformProductPricingSettings'
import PlatformEmailTemplateSettings from './features/PlatformEmailTemplateSettings'

const PlatformSettings = () => {
  return (
    <div>
      <PlatformLogoSettings /> 
      <PlatformProductPricingSettings />
      <PlatformEmailSettings /> 
      <PlatformEmailTemplateSettings />
      <PlatformCrmSettings /> 
      <PlatformApiKeySettings /> 
    </div>
  )
}

export default PlatformSettings