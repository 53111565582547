export async function setPlatformSetting(settingCode: string, settingValue: object) {

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
            settingCode,
            settingValue
        }),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/platform/set-platform-setting`, options);
        if (res.ok) {
            return true;
        } else {
            console.error(`set-matching-engine-settings: Bad response ${res.status}`);
            return false;
        }
        
    } catch(error) {
        console.error(`set-matching-engine-settings: Error occurred ${error}`);
        return false;
    }
}

export async function getPlatformSetting(settingCode: string) {

    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/platform/get-platform-setting?settingCode=${settingCode}`, options);
        if (res.ok) {
            const data = await res.json();
            return data.data;
        } else {
            console.error(`set-matching-engine-settings: Bad response ${res.status}`);
        }
        
    } catch(error) {
        console.error(`set-matching-engine-settings: Error occurred ${error}`);
    }
}

export async function sendTestEmail(emailTo: string, templateId?: string) {

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
            emailTo,
            templateId
        }),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/platform/send-test-email`, options);
        if (res.ok) {
            return true;
        } else {
            console.error(`sendTestEmail: Bad response ${res.status}`);
            return false;
        }
        
    } catch(error) {
        console.error(`sendTestEmail: Error occurred ${error}`);
        return false;
    }
}

export async function getPlatformProducts() {

    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/platform/get-all-platform-products`, options);
        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            console.error(`getPlatformProducts: Bad response ${res.status}`);
        }
        
    } catch(error) {
        console.error(`getPlatformProducts: Error occurred ${error}`);
    }
}

export async function getPlatformProduct(productId:string) {

    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/platform/get-platform-product?productId=${productId}`, options);
        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            console.error(`getPlatformProduct: Bad response ${res.status}`);
        }
        
    } catch(error) {
        console.error(`getPlatformProduct: Error occurred ${error}`);
    }
}

export async function updatePlatformProduct(productId: string | null, productCode: string, name: string, description: string | null, features: string | null, type: string, price: number, discountPercent: number | null, highlight: boolean | null, linkToAdditionalProductInfo: string | null) {

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
            productId,
            productCode,
            name,
            description,
            features,
            type,
            price,
            discountPercent,
            highlight,
            linkToAdditionalProductInfo
        }),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/platform/update-platform-product`, options);
        if (res.ok) {
            return true;
        } else {
            console.error(`updatePlatformProduct: Bad response ${res.status}`);
            return false;
        }
        
    } catch(error) {
        console.error(`updatePlatformProduct: Error occurred ${error}`);
        return false;
    }
}

export async function deletePlatformProduct(productId: string) {

    const options : RequestInit = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
            productId
        }),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/platform/delete-platform-product`, options);
        if (res.ok) {
            return true;
        } else {
            console.error(`deletePlatformProduct: Bad response ${res.status}`);
            return false;
        }
        
    } catch(error) {
        console.error(`deletePlatformProduct: Error occurred ${error}`);
        return false;
    }
}

export async function uploadPlatformLogo(image : Blob) {
    const formData = new FormData();
    formData.append("platformLogo", image);

    const options : RequestInit = {
        method: "POST",
        credentials: "include",
        body: formData
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/platform/upload-logo`, options);
        if (res.ok) {
            return true;
        } else {
            console.error(`uploadPlatformLogo: Bad response ${res.status}`);
            return false;
        }
        
    } catch(error) {
        console.error(`uploadPlatformLogo: Error occurred ${error}`);
        return false;
    }
}

export async function getEmailTemplateIds() {

    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/platform/get-email-template-ids`, options);
        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            console.error(`getEmailTemplateIds: Bad response ${res.status}`);
        }
        
    } catch(error) {
        console.error(`getEmailTemplateIds: Error occurred ${error}`);
    }
}

export async function getEmailTemplate(templateId:string) {

    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/platform/get-email-template?templateId=${templateId}`, options);
        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            console.error(`getEmailTemplate: Bad response ${res.status}`);
        }
        
    } catch(error) {
        console.error(`getEmailTemplate: Error occurred ${error}`);
    }
}

export async function updateEmailTemplate(templateId: string, templateSubject: string, templateContent: string) {

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
            templateId,
            templateSubject,
            templateContent
        }),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/platform/update-email-template`, options);
        if (res.ok) {
            return true;
        } else {
            console.error(`updateEmailTemplate: Bad response ${res.status}`);
            return false;
        }
        
    } catch(error) {
        console.error(`updateEmailTemplate: Error occurred ${error}`);
        return false;
    }
}