export async function logoutUser() {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    };

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/logout`, options);
        
        if (res.ok) {
            const data = await res.json();
            console.log(data);
            return true;
        } else {
            console.error(`logoutUser: Bad response ${res.status}`);
            return false;
        }
    } catch(error) {
        console.error(`logoutUser: Error occurred ${error}`);
        return false;
    }
}

export async function loginUser(inputEmail: string, inputPassword: string) {
    if (!inputEmail || !inputPassword) {
        return {success: false, data: undefined};
    }
    
    const credentials = {
        email: inputEmail,
        password: inputPassword,
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(credentials),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/login`, options);
    
        if (res.ok) {
            const data = await res.json();
            return {success: true, status: res.status, data: data.data};
        } else {
            console.error(`loginUser: Bad response ${res.status}`);
            return {success: false, status: res.status, data: undefined};
        }
    } catch(error) {
        console.error(`loginUser: Error occurred ${error}`);
        return {success: false, status: undefined, data: undefined};
    }
}

export async function checkLogin() {
    
    const options : RequestInit = {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
      },
      credentials: "include"
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/check-login`, options);
    
        if (res.ok) {
            const data = await res.json();
            return {authorized: true, data: data.data};
        } else {
            // TODO: Kann ggf Probleme erzeugen wenn Error Code nicht 401 ist sondern irgendwas anderes???
            console.error(`checkLogin: Bad response ${res.status}`);
            return {authorized: false, data: undefined};
        }
    } catch(error) {
        console.error(`checkLogin: Error occurred ${error}`);
        return {authorized: false, data: undefined};
    }
}

export async function verifyCustomer(verificationCode : string) {
    const payload = {
        verificationCode: verificationCode
    }
    
    const options : RequestInit = {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/verify-customer-email`, options);
    
        if (res.ok) {
            const data = await res.json();
            return {verified: true, status: res.status, data: data.data};
        } else {
            // TODO: Kann ggf Probleme erzeugen wenn Error Code nicht 401 ist sondern irgendwas anderes???
            console.error(`checkLogin: Bad response ${res.status}`);
            return {verified: false, status: res.status, data: undefined};
        }
    } catch(error) {
        console.error(`checkLogin: Error occurred ${error}`);
        return {verified: false, status: undefined, data: undefined};
    }
}

export async function updateUserPasswordManager(userId: string, inputPassword: string) {

    const registrationDetails = {
        userId,
        inputPassword
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(registrationDetails),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/update-user-password-manager`, options);
    
        if (res.ok) {
            const data = await res.json();
            return true;
        } else {
            console.error(`updateUserPasswordManager: Bad response ${res.status}`);
            return false;
        }
    } catch(error) {
        console.error(`updateUserPasswordManager: Error occurred ${error}`);
        return false;
    }   
}