import React, { useEffect, useState } from 'react'
import DocumentChecklistItem from './components/DocumentChecklistItem'
import ArrowDownIcon from '../../../../../../../assets/img/icons/ArrowDownIcon';
import { HighlightArea } from '@react-pdf-viewer/highlight';

const dummyChecklistItems = [
    {id: "1", title: "Scope of Work", details: "Clear description of the scope of work covered by the proposal. Detailed outline of the services to be provided by the vendor."},
    {id: "2", title: "Software Features and Functionality", details: "List of specific features and functionalities included in the ERP system. Customization options available to tailor the software to your organization's needs."},
    {id: "3", title: "Implementation Timeline", details: "Proposed timeline for implementation, including key milestones and deadlines. Any dependencies or prerequisites required from your organization."},
    {id: "4", title: "Cost Breakdown", details: "Transparent breakdown of costs, including upfront fees, licensing costs, and ongoing maintenance fees. Any additional costs for customization, training, or support services."},
    {id: "5", title: "Payment Terms", details: "Payment schedule outlining when payments are due and the accepted methods of payment. Terms for late payments or penalties for non-compliance."},
    {id: "6", title: "Support and Maintenance", details: "Description of the vendor's support services, including response times and support channels. Maintenance schedule and procedures for updates and upgrades."},
    {id: "7", title: "Data Security and Compliance", details: "Details of the security measures implemented by the vendor to protect your data. Compliance with relevant regulations and standards, such as GDPR or HIPAA."},
    {id: "8", title: "Training and Onboarding", details: "Plan for training end-users and administrators on how to use the ERP system. Availability of documentation or resources to facilitate onboarding."},
    {id: "9", title: "Integration and Interoperability", details: "Compatibility with existing systems and technologies within your organization. APIs and integration capabilities for seamless data exchange with external systems."},
    {id: "10", title: "Service Level Agreements (SLAs)", details: "SLAs related to system uptime, performance, and support response times. Penalties or remedies for failing to meet SLAs."},
    {id: "11", title: "Ownership and Intellectual Property Rights", details: "Clarification on ownership of data stored in the ERP system. Intellectual property rights related to any customizations or modifications made to the software."},
    {id: "12", title: "Contract Duration and Renewal Terms", details: "Duration of the contract term and conditions for renewal. Any notice periods required for termination or non-renewal."},
    {id: "13", title: "Exit Strategy", details: "Procedures for transitioning away from the ERP system in case of termination or non-renewal. Data migration and transfer processes to ensure continuity of operations."},
    {id: "14", title: "Vendor Reputation and References", details: "Research on the vendor's reputation within the industry and among their customers. Request for references or case studies from other organizations that have implemented the ERP system."},
    {id: "15", title: "Legal Review", details: "Review of the contract by legal counsel to ensure compliance with applicable laws and regulations. Identification of any clauses or terms that may warrant negotiation or clarification."},
]

interface props {
    comments: any[],
    jumpToHighlight: (comment: any) => void,
    clickedHighlightComment: any,
    checklistItemTagging: any,
    setChecklistItemTagging: React.Dispatch<React.SetStateAction<any>>,
}

const SidebarAnalysis = ({comments, jumpToHighlight, clickedHighlightComment, checklistItemTagging, setChecklistItemTagging} : props) => {
    const [checklistItems, setChecklistItems] = useState<any>();
    const [documentSummaryOpen, setDocumentSummaryOpen] = useState(true);
    const [highlightsOpen, setHighlightsOpen] = useState(false);
    const [warningsOpen, setWarningsOpen] = useState(false);
    const [checklistOpen, setChecklistOpen] = useState(true);
    
    useEffect(() => {
        setChecklistItems(dummyChecklistItems);
    } ,[])

  return (
    <div className='p-4 overflow-y-auto'>
        {/* <div>
            <div onClick={() => setDocumentSummaryOpen(prev => !prev)}
            className='flex items-center cursor-pointer'>
                <label className="block text-sm font-medium text-gray-900">{"Document summary"}</label>
                { documentSummaryOpen ? <div className='rotate-180'><ArrowDownIcon /></div> : <div className=''><ArrowDownIcon /></div>}
            </div>
            {
                documentSummaryOpen ? (
                    <div className="border border-transparent text-gray-900 text-md rounded-lg block w-full py-2.5">
                        This comprehensive platform covers finance, HR, inventory, and sales, with customizable features to suit your specific needs. The offer includes a transparent pricing model, starting at <strong>$1.000 per user per month</strong>, with flexible options tailored to your budget and business size. Additionally, the package includes <strong>50 licenses</strong>, ensuring seamless access for your team. Implementation support is provided at a <strong>one-time fee of $300.000</strong> to facilitate a smooth transition. Ongoing post-implementation support is available at an annual maintenance fee of <strong>$20.000 to ensure sustained efficiency</strong>. Robust security measures are included to protect your data and ensure compliance with industry standards.
                    </div>
                ) : (<></>)
            }
        </div>
        
        <div className='mt-4'>
            <div onClick={() => setHighlightsOpen(prev => !prev)}
            className='flex items-center cursor-pointer'>
                <label className="block text-sm font-medium text-gray-900">{"Highlights"}</label>
                { highlightsOpen ? <div className='rotate-180'><ArrowDownIcon /></div> : <div className=''><ArrowDownIcon /></div>}
            </div>
            {
                highlightsOpen ? (
                    <div className="border border-transparent text-gray-900 text-md rounded-lg block w-full py-2.5">
                    </div>
                ) : (<></>)
            }
        </div>
        
        <div className='mt-4'>
            <div onClick={() => setWarningsOpen(prev => !prev)}
                className='flex items-center cursor-pointer'>
                <label className="block text-sm font-medium text-gray-900">{"Warnings"}</label>
                { warningsOpen ? <div className='rotate-180'><ArrowDownIcon /></div> : <div className=''><ArrowDownIcon /></div>}
            </div>
            {
                highlightsOpen ? (
                    <div className="border border-transparent text-gray-900 text-md rounded-lg block w-full py-2.5">
                    </div>
                ) : (<></>)
            }
        </div> */}
        
        <div className='mt-4'>
            <div onClick={() => setChecklistOpen(prev => !prev)}
            className='flex items-center cursor-pointer'>
                <label className="block text-sm font-medium text-gray-900">{"Checklist"}</label>
                { checklistOpen ? <div className='rotate-180'><ArrowDownIcon /></div> : <div className=''><ArrowDownIcon /></div>}
            </div>
            {
                checklistOpen ? (
                    <div className='space-y-1 mt-4'>
                        {
                            dummyChecklistItems.map((item : any, index : number) => {
                                return (
                                    <DocumentChecklistItem comments={comments} checklistItemData={item} jumpToHighlight={jumpToHighlight} clickedHighlightComment={clickedHighlightComment} checklistItemTagging={checklistItemTagging} setChecklistItemTagging={setChecklistItemTagging} />
                                )
                            })
                        }
                    </div>
                ) : (<></>)
            }
        </div>
    </div>
  )
}

export default SidebarAnalysis